<template>
  <div class="history-chat-box">
    <heads
      title="历史对话"
      :left="left"
    ></heads>
    <van-pull-refresh
      v-model="isLoading"
      @refresh="onRefresh"
      pulling-text="下拉加载更多历史对话信息"
      :loosing-text="fetchNext?'下拉加载更多历史对话信息':'没有更多对话内容'"
    >
      <question-content v-if="historyType==2" :list="chatList"></question-content>
      <chat-content v-else :list="chatList"></chat-content>
    </van-pull-refresh>
  </div>
</template>

<script>
import Header from "@/components/Header";
import ChatContent from "./Components/ChatContent";
import QuestionContent from "./Components/QuestionContent";
import { agentDialogHistory } from "@/fetch/api/chat.js";
export default {
  name: "historychat",
  components: {
    heads: Header,
    ChatContent,
    QuestionContent
  },
  data() {
    return {
      broker_id: localStorage.getItem("broker_id"), //用户id
      chatList: [],
      currentPage: 1, //分页
      isLoading: false,
      fetchNext: true,
      historyType: 0, // 0 普通对话数据，1 核保数据， 2 常见问题数据
      left: "/chat/moment"
    };
  },
  methods: {
    getDialogChat(count) {
      let params = {
        broker_id: this.broker_id,
        currentPage: this.currentPage,
        historyType: this.historyType,
      };
      this.isLoading = true;
      agentDialogHistory(params)
        .then((res) => {
          if (res.code == 200) {
            res.result.results.reverse();
            this.chatList.unshift(...res.result.results);
            this.fetchNext = res.result.next != null ? true : false;
          }
          this.isLoading = false;
        })
        .then(() => {
          if (count == 1) {
            this.$nextTick(() => {
              let container = document.getElementById("chatView");
              container.scrollTop = container.scrollHeight;
            });
          }
        });
    },
    onRefresh() {
      if (this.fetchNext) {
        this.currentPage = ++this.currentPage;
        this.getDialogChat();
      } else {
        this.isLoading = false;
      }
    },
  },
  created() {
    if (location.href.indexOf("type=QuestionChat") != -1){
      this.left = '/chat/QuestionChat'
      this.historyType = 2
    }
    this.getDialogChat(1);
  },
};
</script>

<style lang="scss" scoped>
.history-chat-box {
  .header-nav {
    /deep/ .van-nav-bar__title {
      color: #fff;
      font-size: 0.43rem;
    }
  }
}
</style>
